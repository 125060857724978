#faq {
    margin-top: 3rem;
    position: relative;
    padding: 3rem 0 5rem;
    &::before {
        content: "";
        box-shadow: 0px 0px 10px #00000029;
        width: 100vw;
        max-width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        z-index: -1;
    }
    .faq {
        font-family: Arial, sans-serif;
        max-width: 75%;
        margin: 0 auto;
        padding-top: 0.8rem;
        height: 5.3rem;
        @include media-query(tablet) {
           max-width: initial;
        }
      
        h3 {
            background-color: white;
            color: #4BBAB5;
            padding: 1.2rem;
            margin: 0;
            font-weight: 300;
            cursor: pointer;
            position: relative;
            font-family: "IndustryMedium", Arial, sans-serif !important;
            padding-left: 3rem;
            font-size: 1.6rem;
            font-weight: 500;
            border: 1px solid rgba($color: #7386A2, $alpha: 0.27);
            border-radius: 0.1rem;
            @include media-query(tablet) {
                padding-left: 1.8rem;
                font-size: 1.3rem;
            }
            &::before {
                content: url("./assets/images/plus.svg");
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        p {
            padding: 0;
        }
        &.active {
            border: 1px solid rgba($color: #7386A2, $alpha: 0.27);
            padding: 2rem;
            height: auto;
            h3 {
                background-color: transparent;
                padding-left: 0;
                border: 0;
                &::before {
                    content: url("./assets/images/minus.svg");
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .answer {
        display: none;

        p {
            font-weight: 500;
            color: #707070;
            font-size: 1.6rem;
        }
    }
}
