.card-container-carousel {
    .card {
        box-shadow: 0px 3px 10px #00000029;
        min-height: 338px;

        @include media-query(desktopL) {
            min-height: 310px;
        }

        @media(min-width:1300px) and (max-width:1400px) {
            min-height: 372px;
        }

        img {
            display: block;
            width: 80% !important;
            max-width: 250px;
            margin: 0 auto;
        }

        .info-card-container {
            padding: 0 2rem 2rem 2rem;
            margin-bottom: 2rem;

            h2,
            h3,
            p {
                color: #707070;
                font-size: 1.8rem;

            }

            h2 {
                margin-bottom: 0.8rem;
            }

            h3 {
                margin: 0 0 0.8rem 0;
                font-family: "IndustryMedium", Arial, sans-serif !important;
                font-weight: 300;
                font-size: 1.5rem;
            }

            p,
            a {
                display: flex;
                gap: 0.6rem;
                font-size: 1.4rem;
                margin: 0;
                color: #707070;
                text-decoration: none;

                &::before {
                    content: "";
                    display: block;
                    width: 1.3rem;
                    height: 1.3rem;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-top: 4px;
                }
            }

            p.ubication {
                min-height: 50px;

                &::before {
                    background-image: url("./assets/icons/location-icon.svg");
                }
            }

            a.phone {
                margin-bottom: 5px;

                &::before {
                    background-image: url("./assets/icons/phone-icon.svg");
                }
            }
        }
    }
}