#nuestro-concensionario {
    position: relative;
    margin-top: 2rem;
    @include media-query(tablet) {
        padding: 2rem 15px;
    }
    
    .title-section {
        h2 {
            margin-bottom: 3rem;
            @include media-query(tablet) {
                margin-bottom: 3.5rem;
            }
        }
    }
    .container-carousel {
        max-width: 76%;
        margin: 0 auto;
        // padding: 0 0.8rem;
        @include media-query(tablet) {
            max-width: 70%;
        }
        @include media-query(mobile) {
            max-width: 85%;
        }
        .card-container-carousel {
            padding: 0 0.8rem;

            .owl-stage-outer {
                padding: 0 0.4rem;
                z-index: 2;
                .card {
                    margin-right: 1rem;
                }
            }
            .owl-nav {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                z-index: 1;
                @include media-query(mobile) {
                    top: 40%;
                }
                button {
                    width: 40px;
                    height: 40px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    display: block;
                    outline: none;
                    scale: 1;
                    transition: scale  0.3s ease-in-out;
                    position: absolute;
                    @include media-query(tablet) {
                        width: 25px;
                        height: 25px;
                    }
                    &:hover{
                        scale: 1.1;
                    }
                    span {
                        display: none;
                        content: "";
                    }
                    &.owl-prev {
                        left: -5rem;
                        background-image: url("./assets/icons/arrow_left.svg");
                        @include media-query(mobile) {
                            left: -3rem;
                        }
                    }
                    &.owl-next {
                        right: -5rem;
                        background-image: url("./assets/icons/arrow_right.svg");
                        @include media-query(mobile) {
                            right: -3rem;
                        }
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.9);
                    }
                }
            }
            .owl-dots {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                gap: 1.2rem;
                margin-top: 3rem;
                button {
                    width: 1.8rem;
                    height: 0.1rem;
                    background-color: #cccccc;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0.4rem;

                    &.active,
                    &:hover {
                        width: 5rem;
                        background-color: #000000;
                    }
                    span {
                        display: none;
                    }
                    &:hover {
                    }
                }
            }
        }
    }
}
