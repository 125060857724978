//---------------------------------------------------------------------
// ESTILOS GENERALES
//---------------------------------------------------------------------

html {
    scroll-behavior: smooth;
    height: 100%;
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    font-size: 1.6rem;
    min-height: 100%;
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    margin: 0;
    overflow-x: hidden;
    box-sizing: border-box;
    &.modal-open {
        overflow: hidden;
    }
}

div {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;//quita el margen raro abajo de la img
    object-fit: contain;
    width: 100%;

}

.skip-link {
    display: none;
}

h1,
h2,
h3 {
    font-family: "IndustryDemi", Arial, sans-serif;
}
p,
span,
a,
li, input, button {
    font-family: "IndustryMedium", Arial, sans-serif;
}
main {
    width: 100%;
    margin-top: 7.5rem;
    
    @media (min-width: 768px) {
        margin: 0 auto 0;
    }
    
    section {
        margin-top: 12rem;
        padding: 2rem 0;
    }
}

// ==================== BOOTSTRAP OVERRIDE ====================

.container,
.row {
    --bs-gutter-x: 3rem;
}
