#dejanos-tus-datos {
    margin-top: 0;
    padding: 0;

    @include media-query(desktopXL) {
        margin-top: 0;
    }

    .banner {
        width: 100vw;
        max-width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 20%;
            background: transparent linear-gradient(180deg, #00000083 0%, #00000000 100%) 0% 0% no-repeat padding-box;
        }

        picture {
            @include media-query(tablet) {
                width: 100%;
                display: block;
                height: auto;
            }

            img {
                @include media-query(tablet) {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &-text {
            position: absolute;
            top: 22%;
            left: 15%;
            transform: translate(-15%, -22%);
            z-index: 2;

            @include media-query(desktopXL) {
                top: 20%;
                left: 10%;
            }

            @include media-query(tablet) {
                top: 5%;
                left: 22%;
                transform: translate(-22%, -5%);
            }

            @include media-query(mobile) {
                top: 3%;
            }

            h1 {
                font-size: 4.5rem;
                color: white;
                max-width: 60rem;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                text-transform: uppercase;

                @include media-query(desktopXL) {
                    font-size: 3.8rem;
                    max-width: 45rem;
                }

                @include media-query(mobile) {
                    font-size: 2.5rem;
                    margin: 0;
                }
            }
        }

        .hubspot-form {
            position: absolute;
            background-color: white;
            right: 5%;
            top: 100px;
            transform: translate(-8%, 0%);
            box-shadow: 0px 3px 20px #00000033;
            height: auto;
            width: 40rem;
            padding: 2rem 2rem;
            z-index: 2;

            @include media-query(tablet) {
                position: relative;
                top: -56px;
                right: initial;
                width: 75%;
                margin: 0 auto;
                transform: translateY(-1rem);
            }

            h3 {
                font-size: 2.5rem;
                margin: 1rem 0;
                color: #00332C;
            }

            #hubspot-form {
                form {
                    .legal-consent-container {
                        display: none;
                    }

                    .hs-form-field {

                        position: relative;

                        @include media-query(desktopXL) {
                            margin: -1rem 0 1.6rem;
                        }

                        label {
                            color: #707070;
                            font-size: 1.5rem;

                            @include media-query(desktopXL) {
                                font-size: 1.2rem;
                            }
                        }

                        input,
                        select {
                            width: 98%;
                            height: 3.1rem;
                            margin-top: 0.4rem;
                            border-radius: 0.4rem;
                            border: 1px solid #ADADAD;

                            &.error {
                                border: 1px solid red;
                                background-color: rgba(255, 0, 0, 0.2);
                            }

                            @include media-query(desktopXL) {
                                height: 2.7rem;
                            }
                        }

                        select {
                            width: 100% !important;
                        }

                        ul {
                            margin: 0;
                            padding: 0;
                            list-style: none;

                            li {
                                label {
                                    color: red;
                                    font-size: 1rem;
                                }
                            }
                        }
                    }

                    .hs-fieldtype-booleancheckbox {
                        ul {
                            position: relative;

                            li {
                                label {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    gap: 0.4rem;

                                    input {
                                        width: initial;
                                    }

                                    span {
                                        color: #000000;
                                    }
                                }
                            }
                        }
                    }

                    .hs_submit {
                        margin: 1rem 0;

                        .actions {
                            text-align: center;

                            input {
                                background-color: #4BBAB5;
                                border: none;
                                padding: 1rem 5rem;
                                color: white;
                                font-weight: 600;
                                cursor: pointer;
                                transition: 0.4s;

                                &:hover {
                                    background-color: #000000;
                                    transition: 0.4s;
                                }
                            }
                        }
                    }

                    .hs_error_rollup {
                        display: none;

                        .hs-error-msgs {
                            color: red;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        .complements {
            position: absolute;
            bottom: -19.4%;
            left: 0;
            background-color: #4778b2;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 13.2rem;
            padding-left: 18rem;
            transform: translate(0, 19.4%);
            column-gap: 2rem;

            @include media-query(tablet) {
                height: initial;
                flex-direction: column;
                padding-left: 0;
                bottom: -18%;
                transform: translate(0, 18%);
            }

            .complement {
                width: 16%;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 1.6rem;

                @include media-query(tablet) {
                    flex-direction: column;
                }

                picture {
                    width: 4.4rem;
                    height: 4.5rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                p {
                    color: white;
                    font-weight: 300;
                    width: 10.4rem;
                }
            }
        }
    }
}