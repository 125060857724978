#promocion {
    position: relative;
    margin-top: 4rem;
    padding: 5rem 0;
    text-align: center;
    @include media-query(tablet) {
        margin: 0;
        padding: 5rem 15px;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        background: #00332c;
        z-index: -1;
        width: 100vw;
        max-width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }
    .container-promocion {
        h2 {
            text-align: center;
            width: 100%;
            color: white;
            width: 50rem;
            margin: 0 auto;
            margin-bottom: 1.2rem;
            font-size: 2.8rem;
            text-transform: uppercase;
            @include media-query(tablet) {
                width: initial;
                font-size: 2.3rem;
                margin-bottom: 3rem;
            }
        }
        .container_highlight {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 6.8rem;
            @include media-query(tablet) {
                column-gap: 10px;
            }
            @include media-query(mobile) {
                column-gap: 10px;
            }
            @include media-query(mobileS) {
                flex-direction: column;
            }
            picture{
                width: 24rem;
                @include media-query(tablet) {
                    width: 20rem;
                }
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }

        button {
            margin-top: 2.3rem;
            border: none;
            background-color: transparent;
            text-decoration: underline;
            color: white;
            cursor: pointer;
        }
        // picture {
        //     width: 48rem;
        //     height: 51rem;
        //     @include media-query(desktopXL) {
        //         width: 28rem;
        //         height: 31rem;
        //     }
        //     
        // }
    }
}
