.tool-tip {
    position: relative;
    padding: 20px;
    text-align: center;
    margin-top: 10rem;
    @include media-query(tablet) {
        margin-top: 5rem;
    }
    .container-tooltip {
        display: inline-block;
        width: 32rem;
        background-color: white;
        text-align: center;
        @include media-query(tablet) {
            width: 28rem;
        }
        button {
            display: block;
            margin: 0 auto;
            padding: 1rem 2rem;
            font-size: 1.8rem;
            background-color: #4bbab5;
            color: white;
            border: none;
            border-radius: 4px;
            z-index: 2;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            @include media-query(tablet) {
                font-size: 1.5rem;
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            height: 0.1rem;
            z-index: -1;
            width: 100vw;
            max-width: 100vw;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            background-color: black;
        }
    }
}
