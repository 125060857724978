// FONTS
@font-face {
	font-family: 'IndustryDemi';
	src: url("./assets/font/IndustryDemi.ttf") format("truetype");
}

@font-face {
	font-family: 'IndustryMedium';
	src: url("./assets/font/IndustryDemi.ttf") format("truetype");
}

