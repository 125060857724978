#repuestos {
    position: relative;
    margin-top: 8rem;
    padding: 8rem 0;
    height: 325px;

    @media(min-width:1600px) {
        height: 55vh;
    }

    @media(min-width:1800px) {
        height: 63vh;
    }

    @media(min-width:1900px) {
        height: 63vh;
    }


    @media(max-width: 768px) {
        margin-top: 0;
        padding: 0 0 80rem;
    }

    @media (max-width: 576px) {

        // padding: 0 0 55rem;
        height: 50px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        background-image: url("./assets/images/Franja-image-FREIGHTLINER.jpg");
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: -1;
        width: 100%;

        @media (max-width: 768px) {

            background-image: url("./assets/images/Franja-image-mobil-FREIGHTLINER.jpg");
            background-position: center bottom;

        }

        .container-promocion {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            place-content: center;
            place-items: center;
            gap: 1.2rem;

            .title-section {
                text-align: left;

                p {
                    margin-bottom: 2rem;
                }

                button {
                    border: none;
                    background-color: transparent;
                    text-decoration: underline;
                    color: white;
                    cursor: pointer;
                }

                @media (max-width: 768px) {
                    width: initial;
                }
            }

            @media(max-width: 768px) {
                grid-template-columns: 1fr;
                padding: 2rem;
            }
        }
    }

}