.highlight-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    .highlight-card {
        width: 33.3%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        picture {
            width: 24rem;
            display: block;
            height: 19rem;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        h3{
            font-size: 2rem;
            font-weight: bold;
            color: white;
            margin-top: 2.4rem;
        }
    }
}
.highlight-paragraph {
    text-align: center;
    font-size: 1rem;
    max-width: 60%;
    color: white;
    margin: 0 auto;
    margin-top: 4.6rem;
}
