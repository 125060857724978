#financiacion {
    position: relative;
    margin-top: 0;

    @include media-query(tablet) {
        margin-top: 5rem;
    }

    .container-promocion {
        text-align: center;
        padding: 2rem;

        h2,
        p {
            color: white;
        }

        h2 {
            font-size: 2.8rem;
            margin-bottom: 2rem;
            text-transform: uppercase;

            @include media-query(tablet) {
                font-size: 2.3rem;
            }
        }

        p {
            font-size: 1.2rem;
            margin: 0 auto;
            width: 70%;
            font-weight: 300;
            margin-bottom: 20px;

            @include media-query(tablet) {
                width: 100%;
            }
        }

        a {
            color: #4bbab5;
            text-decoration: underline;
        }
      
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #00332C;
        z-index: -1;
        width: 100vw;
        max-width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }
}